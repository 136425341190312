import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Logo from '../../images/white-logo.png'
import { FaMapMarkerAlt, FaPhoneAlt, FaFacebookSquare, FaInstagram, FaTwitter, FaPinterestP, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import Img1 from '../../images/Footer/img1.jpg'
import Img2 from '../../images/Footer/img2.jpg'
import Img3 from '../../images/Footer/img3.jpg'
import Img4 from '../../images/Footer/img4.jpg'
import Pay from '../..//images/payment.png'



const Footer = (props) => {

  return (
    <div className="black_section black_section1">
    {/* <div className="black_section">
      </div> */}
      <div className="main_footer">
      <footer>
        <Container>
          <Row>
            <Col md="4">
              <div className="footer_logo">
                <a href="/">
                  <img src={Logo} alt="logo" />
                </a>
              </div>
              <div className="address">
                <p><FaMapMarkerAlt size={15} /> Jaipur, Rajasthan - 303704 INDIA</p>
                <p><a href="mailto:info@greyspacefurniture.com"> <HiOutlineMail size={15} /> info@greyspacefurniture.com</a></p>
                <p> <FaPhoneAlt size={15} /> +91 968 033 6336</p>
              </div>
            </Col>
            <Col md="4">
              <div className="footer_card">
                <h4>STAY CONNECTED</h4>
                <p>To know about the latest videos,<br />
                  updates, exhibitions and news
              </p>
                <div className="footer_icon_row">
                  <div className="icon_center">
                    <div className="footer_icon">
                      <span>
                        <a href="https://www.facebook.com/greyspacefurnitures" target="blank"><FaFacebookSquare size={15} color="#000" /> </a>  </span>
                    </div>
                    <div className="footer_icon">
                      <span>
                        <a href="https://www.instagram.com/greyspacefurniture/" target="blank"><FaInstagram size={15} color="#000" /> </a>  </span>
                    </div>
                    <div className="footer_icon">
                      <span>
                        <a href="https://twitter.com/GreySpaceIndia" target="blank"><FaTwitter size={15} color="#000" /> </a>  </span>
                    </div>
                    <div className="footer_icon">
                      <span>
                        <a href="https://in.pinterest.com/GreySpaceFurniture/" target="blank"><FaPinterestP size={15} color="#000" /> </a>  </span>
                    </div>
                    <div className="footer_icon">
                      <span>
                        <a href="https://www.linkedin.com/company/greyspacefurniture/?originalSubdomain=in" target="blank"><FaLinkedinIn size={15} color="#000" /> </a>  </span>
                    </div>
                    <div className="footer_icon">
                      <span>
                        <a href="https://api.whatsapp.com/message/Q4TLUUE67MEQD1" target="blank"><FaWhatsapp size={15} color="#000" /> </a>  </span>
                    </div>
                  </div>
                </div>
  
              </div>
            </Col>
            <Col md="4">
              <div className="footer_card_img">
                <h4>JUST TAKE A LOOK</h4>
                <div className="footer_card_img">
                  <img src={Img1} alt="img" />
                  <img src={Img2} alt="img" />
                  <img src={Img3} alt="img" />
                  <img src={Img4} alt="img" />
                </div>

              </div>
            </Col>
          </Row>
          
        </Container>
      </footer>  
    </div>
    <div className="bottom_footer">
      <Container>
        <Row>
          <Col md="4">
            <p>© 2021 GREY SPACE | All Rights Reserved</p>
          </Col>
          <Col md="4">
            <div className="footer_center">
            <img src={Pay} alt="img" />
            </div>
          </Col>
          <Col md="4">
            <div className="bottom_footer_card">
            <a href="/PrivacyPolicy">Privacy Policy</a>
            <div className="footer_devaider">
              <a href="/FAQ" >FAQ</a>
            </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>  
    </div>

  );
}

export default Footer;