import React from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/index.css'
import '../style/Poppins-font.css'
import Header from './Header'
import Footer from './Footer'
import Modal from './ModalMain'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




const Layout = ({ path, title, children }) => {

  return (
    <>
      <Helmet>
        <html lang="en" />
        {/* <title>{title}</title> */}
        
        <body className="fp-responsive" />
        
      </Helmet>
      <div>
        
        <Header/>
        <main>{children}</main>
        <Footer/>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
